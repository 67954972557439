@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&display=swap');
@import './vars.scss';


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: 'Montserrat', sans-serif;
  -moz-osx-font-smoothing: 'Montserrat', sans-serif;
}

.w-100 {
  width: 100%;
}

.text-white {
  color: white;
}

a:link {
  text-decoration: none;
}

.text-center {
  text-align: center !important;
}

@media (min-width: $xs) {
  html, body { 
    font-size: 13px; 
  }
}

@media (min-width: $sm) {
  html, body { 
    font-size: 13px; 
  }
}

@media (min-width: $md) {
  html, body { 
    font-size: 16px; 
  }
}

@media (min-width: $lg) {
  html, body { 
    font-size: 16px; 
  }
}

@media (min-width: $xl) {
  html, body { 
    font-size: 20px; 
  }
}

.fp-watermark {
  display: none;
}