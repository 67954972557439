$xl: 1920px;
$lg: 1280px;
$md: 960px;
$sm: 600px;
$xs: 0px;

$text-color: #404854;
$text-color-semi: #03619c;
$background-blue-light: #3c7dd8;
$color-blue-light: #59A6FF;
$background-blue: #139CE1;
$background-green: #49C24A;
$text-color-important: #1e3c6f;
$color-line: #2884b0;
$color-form: rgb(0, 170, 209);
$color-yellow: #ff8b00;
$color-orange: #ff4800;
$color-gray-dark: #878a8e;
$color-phone: #ff5b00;
$back-services: #EDF7FF;
$color-yellow: #FEF900;
$back-main: #526B84;
$btn-green: #019A7D;
$btn-pink: #F26772;
$btn-yellow: #F2BC12;


$color-button: #96FF0D;
$color-text-button: #004206;
$color-text-icon: #4D6880;