@import './../../../../../vars.scss'; 

.posts {

  margin: 3em auto 9em auto;
  max-width: 1200px;

  .title {
    color: #1e3c6f;
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: 200;
    text-transform: uppercase;


    @media (max-width: 600px) {
      & {
        font-size: 1.5em;
      }
    }
  }

  .createdBy {
    color: #a4a4a5;
    font-size: 1.2em;
    font-weight: 300;
  }

  .summary {
    font-weight: 300;
    color: #404854;
    margin-bottom: 3em;
    p {
      font-size: 1.1em;
    }
  }

  .btn-full-note {
    background-color: #42d1ab;
    color: white;
    padding: .7em 2.2em;
    font-weight: 300;
    text-transform: uppercase;
  }

  .btn-full-note:hover {
    background-color: #21aa83;
  }

  a {
    text-decoration: none;
  }
}