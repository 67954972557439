@import './../../../vars.scss';

.container-navbar {

  .MuiFab-root:hover {
    text-decoration: none;
    background-color: white;
  }

  .btn-icon-menu {
    z-index: 1400;
    background-color: transparent;
    border: solid #ffec00 1px;
    position: fixed;
    right: 6em;
    top: 2em;
    width: 2em;
    height: 2em;
    min-height: auto;
    min-width: auto;
    padding: .7em;
    box-sizing: content-box;
    transition: right 200ms;
    box-shadow:
      0px 3px 5px -1px rgba(0, 74, 119,0.2),
      0px 6px 10px 0px rgba(0, 74, 119,0.14),
      0px 1px 18px 0px rgba(0, 74, 119,0.12);
    .icon-show-menu {
      transform: rotate(0deg);
      transition: transform 300ms;
    }

    &.close {
      right: 16em;
      .icon-show-menu {
        transform: rotate(-90deg);
      }
    }
  }

  @media (max-width: $sm) {
    .btn-icon-menu {
      top: 3.3em;
      right: 3em;
      width: 3em;
      height: 3em;
    }
  }
}

.icon-show-menu {
  width: 100%;
}

.container-close-menu {
  margin: 5.5em 0;
  .btn-icon-menu-close {
    z-index: 99;
    background-color: white;
    right: -30px;
    top: 5%;
    width: 2em;
    height: 2em;
    min-height: auto;
    min-width: auto;
    padding: .7em;
    box-sizing: content-box;
    .icon-show-menu {
      transform: rotate(-90deg);
    }
  }  
  .MuiFab-root {
    box-shadow: none !important;
  }
}

@media (max-width: $sm) {
  .container-close-menu  {
    margin: 7.5em 0;
  }
}

.navBar-right {
  .MuiPaper-root {
    width: 19em;
  }
  .MuiListItem-button:hover {
    background-color: #1e3c6f !important;
  }

  .MuiBackdrop-root {
    background-color: #001d7f8c !important;
  }
  
  .MuiPaper-root {
    color: white !important;
    background-color: #436C87 !important;
  }

  .split-section {
    margin: 1em;
    font-style: italic;
    font-weight: 100;
  }

  .container-menu-secundary {
    a {
      div {
        padding-left: 21px;
      }
    }
  }
}

.container-links-navbar {
  a {
    font-size: inherit;
    font-style: inherit;
    color: inherit;
    text-decoration: none;
  }
}

.list-secondary-pages {
  span {
    font-size: .8em;
  }
}