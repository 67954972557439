@import './../../../../vars.scss';

.i-meetings {
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $lg) {
    & {
      background-position: 20% 50%;
    }
  }
  @media (max-width: $sm) {
    & {
      display: inline;
      height: auto;
    }
  }
  .container-columns {
    display: grid;
    column-gap: 20px;
    row-gap: 15px;
    align-items: center;
    grid-template-columns: auto;

    @media (max-width: $sm) {
      & .first {
        order: 2;
      }
      & .second {
        order: 1;
      }
    }

    @media (min-width: $sm) {
      & {
        grid-template-columns: 420px auto;
      }
    }

    @media (min-width: $md) {
      & {
        grid-template-columns: 580px auto;
      }
    }

    @media (min-width: $lg) {
      & {
        grid-template-columns: 640px auto;
      }
    }

    @media (min-width: $xl) {
      & {
        grid-template-columns: 850px auto;
      }
    }

    @media (max-width: $sm) {
      .first {
        display: none;
      }
    }

    .img-section {
      width: auto;
      max-width: 100%;
      max-height: 100vh;
      margin-right: auto;
      display: block;
    }
  }

  .container-info {
    padding: 2em;

    .container-colums {
      display: grid;
      column-gap: 30px;
      row-gap: 15px;
      grid-template-columns: 200px auto;
      align-items: center;
      @media (max-width: $lg) {
        & {
          grid-template-columns: auto;
        }

        .icon-text-section {
          width: 100%;
          max-width: 200px;
          margin: auto;
          display: block;
        }
      }

      .sub-title {
        margin-top: 0;
        margin-bottom: .8em;
        font-size: 3em;
        color: #4D6880;
        font-weight: 400;
        .bold {
          font-weight: 700;
        }
      }

      .list-section {
        list-style-type: none;
        padding: 0;
        li {
          padding-bottom: 1em;
        }
      }

      button {
        background-color: $btn-pink;
        color: white;
        font-size: 1.3em;
        padding: .7em 1.3em;
        border-radius: 10px;
      }

      .container-btns {
        display: flex;
        a {
          text-decoration: none;
          color: #ff934b;
        }
        .btn {
          text-align: center;
          margin-right: 20px;
          img {
            width: 60px;
          }
          div {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .container-title-contact {
    margin-top: 3em;
    .title {
      margin-right: 1em;
      margin-bottom: 1em;
      color: #004206;
      font-size: 1.5em;
      display: inline-block;
    }
    button {
      border: solid #01C323 3px;
      background-color: transparent;
      color: #01C323;
      font-size: 1.3em;
      padding: 0.3em 1.3em;
      border-radius: 10px;
    }
    button:hover {
      border: solid #01C323 3px;
      background-color: #01C323;
      color: white;
      font-size: 1.3em;
      border-radius: 10px;
    }
  }
  .text-gray {
    color: $color-gray-dark;
  }
  .text-subsection {
    margin-top: 1em;
  }
  .text-llamanos {
    margin-top: 1em;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      margin-left: 10px;
      display: flex;
      align-items: center;
      color: #01C323;
      font-size: 1.4em;
    }
    img {
      margin-left: 10px;
      width: 45px;
    }
  }
}