@import './../../../../vars.scss';

.container-our-benefists {

  min-height: 100vh;
  background-size: cover;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  .owl-theme .owl-nav.disabled+.owl-dots {
    width: 100vw;
  }

  .container-background {
    // padding: 6em 0 6em 0px;
    display: grid;
    align-items: center;

    .title {
      color: white;
      font-size: 2.5em;
      font-weight: 500;
      margin-bottom: 1.7em;
      width: 100vw;
    }
  }

  .container-slider {
    .container-item {
      margin-bottom: 4em;
      .number {
        text-align: center;
        color: $color-yellow;
        font-size: 3em;
        font-weight: 600;
        margin-bottom: .5em;
      }
      .sub-title {
        color: white;
        font-size: 1.5em;
        font-weight: 500;
        margin-bottom: .8em;
      }
      .text {
        color: white;
        font-size: 1em;
        font-weight: 400;
      }
    }
  }

  .owl-theme .owl-dots .owl-dot.active span {
    border: solid $color-yellow 1px !important;
    background: $color-yellow !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px !important;
    height: 12px !important;
    border: solid $color-yellow 1px !important;
    background: transparent !important;
  }
}
