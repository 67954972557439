@import './../../../../vars.scss';

.section-header-post {
  position: relative;
  .section-background {
    height: 60vh;
    min-height: 710px;
    width: 100vw;
    background-position: 0% 100%;
    background-size: cover;
    display: flex;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow:  none;
    margin-bottom: -3em;

    .container-text {
      margin: 0;
      position: absolute;
      top: 148px;
      width: 100%;
      text-align: center;
    }
  
    .section-text-center {
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      font-size: 2em;
      line-height: 1.2;
      -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

      h1 {
        margin-bottom: 2rem;
      }

      h2 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: #03619c;
      }
    }
  } 
}
