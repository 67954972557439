@import './../../../../vars.scss';

.container-our-services {
  padding: 4em 0;
  .title {
    margin-bottom: 1em;
    text-align: center;
    font-size: 3em;
    color: $color-text-icon;
    font-weight: 600;
  }

  .container-icons {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-gap: 1rem;
    row-gap: 50px;

    @media (max-width: $lg) {
      & {
        --auto-grid-min-size: 24rem !important;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr)) !important;
      }
    }

    .container-icon {
      cursor: pointer;
      svg {
        width: 100%;
        max-height: 280px;
        path {
          transition: all 250ms;
        }
      }
    }

    .container-text {
      margin-top: 20px;
      text-align: center;
      font-size: 1.5em;
      font-weight: 600;
      color: $color-text-icon;

      @media (max-width: $sm)  {
        & {
          font-size: 2em;
        }
      }
    }
  }

  .container-icon.earth {
    .cls-earth-1 {
      fill: white;
    }
    .cls-earth-2{
      fill: #516b84;
    }
    .cls-earth-3{
      fill: transparent;
    }

    &:hover {
      .cls-earth-1 {
        fill: #0089c6;
      }
      .cls-earth-2{
        fill: #516b84;
      }
      .cls-earth-3{
        fill: #43d805;
      }
    }
  }

  .container-icon.person {
    svg {
      padding-right: 71px;
    }
    .cls-person-1 {
      fill: white;
    }
    .cls-person-2 {
      fill:#516b84;
    }
    .cls-person-3 {
      fill:none;stroke:#516b84;stroke-linecap:round;stroke-linejoin:round;stroke-width:8px;
    }
    .cls-person-4{
      fill: transparent;
    }

    &:hover {
      .cls-person-1 {
        fill:#ff8500;
      }
      .cls-person-2 {
        fill:#516b84;
      }
      .cls-person-3 {
        fill:none;stroke:#516b84;stroke-linecap:round;stroke-linejoin:round;stroke-width:8px;
      }
      .cls-person-4{
        fill:#ff001f;
      }
    }
  }

  .container-icon.light {
    svg {
      padding-right: 45px;
    }
    .cls-light-1{
      fill:white;
    }
    .cls-light-2{
      fill:#516b84;
    }
    .cls-light-3{
      fill:transparent;
    }

    &:hover {
      .cls-light-1{
        fill:#fff800;
      }
      .cls-light-2{
        fill:#516b84;
      }
      .cls-light-3{
        fill:#ff9100;
      }
    }
  }

  .container-icon.palm {
    svg {
      padding-right: 20px;
    }
    .cls-palm-1 {
      fill:white;
    }
    .cls-palm-2 {
      fill: white;
    }
    .cls-palm-3 {
      fill:#516b84;
    }
    .cls-palm-4 {
      fill: transparent;
    }
    .cls-palm-5 {
      fill: transparent;
    }

    &:hover {
      .cls-palm-1 {
        fill:#ff8896;
      }
      .cls-palm-2 {
        fill:#0ccfef;
      }
      .cls-palm-3 {
        fill:#516b84;
      }
      .cls-palm-4 {
        fill:#f36772;
      }
      .cls-palm-5 {
        fill:#f26772;
      }
    }
  }
}