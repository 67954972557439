@import './../../../../vars.scss';

.container-header-home {
  .section-background {
    height: 100vh;
    min-height: 700px;
    width: 100vw;
    background-position: 0% 0%;
    background-size: cover;
    display: flex;

    a {
      color: white;
    }
  
    .section-text-left {
      font-weight: bold;
      color: white;
      line-height: 1.2;
      -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      h1 {
        font-size: 8em;
        text-align: center;
        margin: 0em;
        @media (max-width: $sm) {
          font-size: 5em;
        }
      }
    }
  
    .text-header-main {
      line-height: 1.2;
      color: white;
      h2 {
        margin: 0em;
        text-align: center;
        font-size: 2em;
        font-weight: 400;
        @media (max-width: $sm) {
          font-size: 1.3em;
        }
      }
    }
  
    .container-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;
    }

    .container-logo-main {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      top: 0;
      margin-top: 40px;
      padding-right: 8.5em;
      .logo {
        max-width: 10em;
      }

      .container-phone {
        padding-top: .7em;
        color: white;
        div {
          display: flex;
          align-items: center;
        }
        img {
          display: inline-block;
          width: 30px;
          margin-right: 1em;
        }
      }
    }

    .container-button {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin-bottom: 3em;
      button {
        background-color: transparent;
        border: solid white 3px;
        color: white;
        font-size: 1.3em;
        padding: .7em 1.3em;
        border-radius: 10px;
      }
      button:hover {
        background-color: white;
        border: solid white 3px;
        color: #3B86C2;
        font-size: 1.3em;
        padding: .7em 1.3em;
        border-radius: 10px;
      }
    }
  }
  
  @media (max-width: $lg) and (orientation: portrait) {
    .section-background {
      align-items: normal;
      height: 100vh !important;
      min-height: 508px !important;
      background-position: 30% 0%;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
  
  @media (max-width: $lg) and (orientation: landscape) {
    .section-background {
      align-items: normal;
      height: 100vh !important;
      background-position: 20% 0%;
      min-height: 508px !important;
      .section-text-left {
        margin-top: 1em;
      }
    }
  }
  
  @media (max-width: $sm) and (orientation: portrait)  {
    .section-background {
      align-items: normal;
      height: 100vh !important;
      background-position: 50% 0%;
      min-height: 100vh !important;
      .section-text-left {
        margin-top: 1em;
      }
      .text-header-main {
        font-size: 1.5em;
      }
    }
  }
  
  @media (max-width: $sm) and (orientation: landscape)  {
    .section-background {
      align-items: normal;
      height: 100vh !important;
      min-height: 100vh !important;
      background-position: 50% 0%;
      .section-text-left {
        margin-top: 1em;
      }
      .text-header-main {
        font-size: 1.5em;
      }
    }
  }
}