@import './../../../../vars.scss';

.i-marketing {
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $lg) {
    & {
      background-position: 70% 50%;
    }
  }
  @media (max-width: $sm) {
    & {
      display: inline;
      height: auto;
    }
  }
  .container-columns {
    display: grid;
    column-gap: 20px;
    row-gap: 15px;
    align-items: center;
    grid-template-columns: auto;
    justify-content: right;

    .first {
      width: 100%;
      max-width: 700px;
    }

    @media (min-width: $sm) {
      & {
        grid-template-columns: auto 460px;
      }
    }

    @media (min-width: $md) {
      & {
        grid-template-columns: auto 620px;
      }
    }

    @media (min-width: $lg) {
      & {
        grid-template-columns: auto 640px;
      }
    }

    @media (min-width: $xl) {
      & {
        grid-template-columns: auto 850px;
      }
    }

    @media (max-width: $sm) {
      & {
        display: inline;
      }
      .second {
        display: none;
      }
    }

    .img-section {
      width: auto;
      max-width: 100%;
      max-height: 100vh;
      margin-left: auto;
      display: block;
    }
  }

  .container-info {
    padding: 2em;

    .container-colums {
      display: grid;
      column-gap: 30px;
      row-gap: 15px;
      grid-template-columns: 200px auto;
      align-items: center;
      @media (max-width: $lg) {
        & {
          grid-template-columns: auto;
        }

        .icon-text-section {
          width: 100%;
          max-width: 200px;
          margin: auto;
          display: block;
        }
      }

      .sub-title {
        margin-top: 0;
        margin-bottom: .8em;
        font-size: 3em;
        color: #4D6880;
        font-weight: 400;
        .bold {
          font-weight: 700;
        }
      }

      .list-section {
        list-style-type: none;
        padding: 0;
        li {
          padding-bottom: 1em;
        }
      }

      button {
        background-color: $btn-yellow;
        color: white;
        font-size: 1.3em;
        padding: .7em 1.3em;
        border-radius: 10px;
      }

      .container-btns {
        display: flex;
        a {
          text-decoration: none;
          color: #777777;
        }
        .btn {
          text-align: center;
          margin-right: 20px;
          img {
            width: 60px;
          }
          div {
            margin-top: 5px;
          }
        }
      }
    }
  }
}