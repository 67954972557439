@import './../../../vars.scss';

.container-footer {
  background-color: #3B86C2;
  color: white;
  .container-info-footer {
    padding: 3em 0;
    display: grid;
    column-gap: 20px;
    row-gap: 50px;
    grid-template-columns: auto 55%;

    @media (max-width: $sm) {
      & {
        grid-template-columns: auto;
        .left {
          order: 2;
        }
        .right {
          order: 1;
        }
      }
    }

    a.tel, a.link-address {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: white;
    }

    a {
      display: flex;
      align-items: center;
      color: white;
    }
  }

  .link-address-1 {
    margin-bottom: -6px;
  }

  .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
    color: white;
  }
  .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(255, 255, 255);
  }
  .MuiInputBase-input {
    font-size: 1.5em;
    color: white;
  }
  .input-form {
    margin-bottom: 1em;
  }

  .w-100 {
    width: 100%;
  }

  button {
    border: solid 3px white;
    color: white;
    font-size: 1.3em;
    padding: .5em 3em;
    border-radius: 15px;
  }
  button:hover {
    border: solid 3px white;
    background-color: white;
    color: #3b86c2;
    font-size: 1.3em;
    padding: .5em 3em;
    border-radius: 15px;
  }

  .text-right {
    text-align: right;
  }

  .title-1 {
    font-size: 1.5em;
  }
  .title-2 {
    font-size: 1.6em;
  }
  .title-3 {
    font-size: 1.3em;
    margin-bottom: 1em;
  }

  .hours-1 {
    font-weight: 500;
  }
  .hours-2 {
    margin-bottom: 1em;
  }

  .section-address {
    font-size: .8em;
  }

  .icon-pin {
    margin-left: 10px;
    width: 20px;
    position: relative;
  }

  .div-footer {
    border-top: solid 1px #ffffff7a;
    font-size: .7em;
    padding: 2em;
  }

  .container-logos-footer {
    padding-top: 60px;
    display: flex;
    .logo-footer {
      width: 100px;
      margin-right: 20px;
    }
  }

  .icon-social {
    width: 40px;
  }

  .container-btn-send{
    display: flex;
    margin-top: 2em;
    justify-content: space-between;

    .reserva {
      font-size: 1.5em;
      display: flex;
      align-items: center;
      margin-right: .5em;
    }
  }
}