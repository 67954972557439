@import './../../../../vars.scss';

.section-comments {
  h2 {
    font-size: inherit;
    font-weight: inherit;
  }
  margin-top: 3em;
  .title {
    color: $back-main;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 1.5em;
    @media (max-width: $sm) {
      & {
        font-size: 1.5em;
      }
    }
  }

  .container-slider-comments {
    margin-bottom: 3em;
    .text {
      font-weight: 300;
      margin-bottom: 1.5em;
    }
    .author {
      font-weight: 600;
      font-size: 1.2em;
    }
    .profession {
      font-weight: 300;
      font-size: .8em;
      margin-bottom: 3em;
    }
  }

  .owl-theme .owl-dots .owl-dot.active span {
    border: solid $back-main 1px !important;
    background: $back-main !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 8px !important;
    height: 8px !important;
    border: solid $back-main 1px !important;
    background: white !important;
  }
}